/*   
 * Template Name: Evan Granito
 * Version: 0.1
 * Author: Evan Granito
 * Website: http://evangranito.com
*/
/* styles.css */
/* ======= Base ======= */
body {
  font-family: 'Roboto', sans-serif;
  color: #545E6C;
  background: transparent;
  font-size: 14px;
  padding: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*
@media (max-width: 767px) {
    body{ 
        padding: 0;
    }
}
*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}
a {
  color: #4169e1;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}
a:hover {
  text-decoration: underline;
  color: #1a454f;
}
a:focus {
  text-decoration: none;
}
p {
  line-height: 1.5;
}
.wrapper {
  background: #4169e1;
  max-width: 960px;
  margin: 0 auto;
  position: relative;
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.sidebar-wrapper {
  background: #4169e1;
  position: absolute;
  right: 0;
  width: 240px;
  height: 100%;
  /*min-height: 800px;*/
  color: #fff;
}
.sidebar-wrapper a {
  color: #fff;
}
.sidebar-wrapper .profile-container {
  padding: 30px;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
}
.sidebar-wrapper .name {
  font-size: 32px;
  font-weight: 900;
  margin-top: 0;
  margin-bottom: 10px;
}
.sidebar-wrapper .tagline {
  color: rgba(255, 255, 255, 0.6);
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
}
.sidebar-wrapper .profile {
  margin-bottom: 15px;
}
.sidebar-wrapper .contact-list .fa {
  margin-right: 5px;
  font-size: 18px;
  vertical-align: middle;
}
.sidebar-wrapper .contact-list li {
  margin-bottom: 15px;
}
.sidebar-wrapper .contact-list li:last-child {
  margin-bottom: 0;
}
.sidebar-wrapper .contact-list .fa {
  font-size: 14px;
}
.sidebar-wrapper .container-block {
  padding: 30px;
}
.sidebar-wrapper .container-block-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 15px;
}
.sidebar-wrapper .degree {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 5px;
}
.sidebar-wrapper .education-container .item {
  margin-bottom: 15px;
}
.sidebar-wrapper .education-container .item:last-child {
  margin-bottom: 0;
}
.sidebar-wrapper .education-container .meta {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0;
}
.sidebar-wrapper .education-container .time {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  margin-bottom: 0px;
}
.sidebar-wrapper .languages-container .lang-desc {
  color: rgba(255, 255, 255, 0.6);
}
.sidebar-wrapper .languages-list {
  margin-bottom: 0;
}
.sidebar-wrapper .languages-list li {
  margin-bottom: 10px;
}
.sidebar-wrapper .languages-list li:last-child {
  margin-bottom: 0;
}
.sidebar-wrapper .interests-list {
  margin-bottom: 0;
}
.sidebar-wrapper .interests-list li {
  margin-bottom: 10px;
}
.sidebar-wrapper .interests-list li .fa {
  font-size: 14px;
}
.sidebar-wrapper .interests-list li:last-child {
  margin-bottom: 0;
}
.main-wrapper {
  background: #fff;
  padding: 60px;
  padding-right: 300px;
}
.main-wrapper .section-title {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  color: #4169e1;
  position: relative;
  margin-top: 0;
  margin-bottom: 20px;
}
.main-wrapper .section-title .fa {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: inline-block;
  color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background: #4169e1;
  text-align: center;
  padding-top: 8px;
  font-size: 16px;
  position: relative;
  top: -2px;
}
.main-wrapper .section {
  margin-bottom: 60px;
}
.main-wrapper .experiences-section .item {
  margin-bottom: 30px;
}
.main-wrapper .upper-row {
  position: relative;
  overflow: hidden;
  margin-bottom: 2px;
}
.main-wrapper .job-title {
  color: #3F4650;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}
.main-wrapper .time {
  position: absolute;
  right: 0;
  top: 0;
  color: #909090;
}
.main-wrapper .company {
  margin-bottom: 10px;
  color: #909090;
}
.main-wrapper .project-title {
  font-size: 16px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 5px;
}
.main-wrapper .projects-section .intro {
  margin-bottom: 30px;
}
.main-wrapper .projects-section .item {
  margin-bottom: 15px;
}
.certificates-section {
  display: block;
}
.certificates-section .item {
  margin-bottom: 15px;
}
.certificates-section .cert-title {
  color: #3F4650;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}
.skillset .item {
  margin-bottom: 15px;
  overflow: hidden;
}
.skillset .level-title {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 12px;
}
.skillset .level-bar {
  height: 12px;
  background: #f5f5f5;
}
.skillset .level-bar-inner {
  height: 12px;
  background: #4169e1;
}
.footer {
  zindex: 2;
  padding: 30px;
  padding-top: 60px;
}
.footer .copyright {
  line-height: 1.6;
  color: #545E6C;
  font-size: 14px;
}
.footer .fa-heart {
  color: #fb866a;
}
/* Extra small devices (phones, less than 768px) */
@media (max-width: 767px) {
  .sidebar-wrapper {
    position: static;
    width: inherit;
  }
  .main-wrapper {
    padding: 30px;
  }
  .main-wrapper .time {
    position: static;
    display: block;
    margin-top: 5px;
  }
  .main-wrapper .upper-row {
    margin-bottom: 0;
  }
}
/* Small devices (tablets, 768px and up) */
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .skillset .level-title {
    display: inline-block;
    float: left;
    width: 30%;
    margin-bottom: 0;
  }
  .skillset .level-bar {
    display: inline-block;
    width: 70%;
    float: left;
    position: relative;
    top: 1px;
  }
}
@media (min-width: 992px) {
  .certificates {
    display: block;
    float: left;
    margin-bottom: 0;
  }
}
/* Large devices (large desktops, 1200px and up) */
/* Ex-Large devices (large desktops, 1200px and up) */

/* 
used for profile image
*/
.profile-img {
  max-width: 100px;
  margin-bottom: 15px;
  border: 0px solid #fff;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.matrix-container {
    display: block;
}
#matrix-canvas {
    background-color: #000;
    font-size: 122px;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 0;
}
.matrix-canvas {
    color: lime;
    font: 12px Space Mono, monospace;
}
